body {
    background-color: white;
}

.conduction_grad_0 {
    -webkit-filter: blur(16px) contrast(1.2) hue-rotate(280deg);
            filter: blur(16px) contrast(1.2)  hue-rotate(280deg);
            background: repeating-linear-gradient(
              190deg,
              rgb(255 0 0 / 50%) 40px,
              rgb(255 153 0 / 50%) 80px,
              rgb(255 255 0 / 50%) 120px,
              rgb(0 255 0 / 50%) 160px,
              rgb(0 0 255 / 50%) 200px,
              rgb(75 0 130 / 50%) 240px,
              rgb(238 130 238 / 50%) 280px,
              rgb(255 0 0 / 50%) 300px
            ),
            repeating-linear-gradient(
              -190deg,
              rgb(255 0 0 / 50%) 30px,
              rgb(255 153 0 / 50%) 60px,
              rgb(255 255 0 / 50%) 90px,
              rgb(0 255 0 / 50%) 120px,
              rgb(0 0 255 / 50%) 150px,
              rgb(75 0 130 / 50%) 180px,
              rgb(238 130 238 / 50%) 210px,
              rgb(255 0 0 / 50%) 230px
            ),
            repeating-linear-gradient(
              23deg,
              red 50px,
              orange 100px,
              yellow 150px,
              green 200px,
              blue 250px,
              indigo 300px,
              violet 350px,
              red 370px
            );
            
}

.conduction_grad {
    -webkit-filter: blur(16px) contrast(1.2) hue-rotate(200deg);
            filter: blur(16px) contrast(1.2)  hue-rotate(200deg);
            opacity: 0.5;
            mix-blend-mode: difference;
            background: repeating-linear-gradient(
              190deg,
              rgb(255 0 0 / 50%) 40px,
              rgb(255 153 0 / 50%) 80px,
              rgb(255 255 0 / 50%) 120px,
              rgb(0 255 0 / 50%) 160px,
              rgb(0 0 255 / 50%) 200px,
              rgb(75 0 130 / 50%) 240px,
              rgb(238 130 238 / 50%) 280px,
              rgb(255 0 0 / 50%) 300px
            ),
            repeating-linear-gradient(
              -190deg,
              rgb(255 0 0 / 50%) 30px,
              rgb(255 153 0 / 50%) 60px,
              rgb(255 255 0 / 50%) 90px,
              rgb(0 255 0 / 50%) 120px,
              rgb(0 0 255 / 50%) 150px,
              rgb(75 0 130 / 50%) 180px,
              rgb(238 130 238 / 50%) 210px,
              rgb(255 0 0 / 50%) 230px
            ),
            repeating-linear-gradient(
              23deg,
              red 50px,
              orange 100px,
              yellow 150px,
              green 200px,
              blue 250px,
              indigo 300px,
              violet 350px,
              red 370px
            );
            animation: rotate360 60s ease-in-out infinite;
}

.rotate360 {
  animation: rotate360 60s linear infinite;
}
.rotate360_r {
  animation: rotate360 90s linear infinite;
  animation-direction: reverse;
}
@keyframes rotate360 {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg)
  }
}



.overpass {
  font-family: 'Overpass Mono', monospace;
  font-weight: 400;
  font-style: normal;
}

.inter {

  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}


/* HTML: <div class="loader"></div> */
.loader_inf1 {
  display: inline-grid;
  padding: 5px;
  -webkit-mix-blend-mode: color-dodge;
  mix-blend-mode: color-dodge;
  filter: blur(4px) contrast(12);
  -webkit-filter: blur(4px) contrast(12);
}
.loader_inf1:before,
.loader_inf1:after {
  content: "";
  grid-area: 1/1;
  height: 40px;
  aspect-ratio: 3;
  --c: #0000 64%,#fff 66% 98%,#0000 101%;
  background:
    radial-gradient(35% 146% at 50% 159%,var(--c)) 0 0,
    radial-gradient(35% 146% at 50% -59%,var(--c)) 100% 100%;
  background-size: calc(200%/3) 50%;
  background-repeat: repeat-x;
  clip-path: inset(0 100% 0 0);
  animation: l16 15s infinite ease-in-out;
}
.loader_inf1:after {
  scale: -1 1;
  -webkit-transform: scale(-1, 1);
  animation-delay: -.75s;
}
@keyframes l16{
  50% {clip-path: inset(0)}
  to {clip-path: inset(0 0 0 100%)}
}


/* HTML: <div class="loader"></div> */
.loader_inff {
  display: inline-grid;
  padding: 10px;
  background: #fff;
  -webkit-filter: blur(20px) contrast(10) invert();
  filter: blur(20px) contrast(10) invert();
  mix-blend-mode: exclusion;
  -webkit-mix-blend-mode: exclusion;
}
.loader_inff:before,
.loader_inff:after {
  content: "";
  grid-area: 1/1;
  height: 120px;
  aspect-ratio: 3;
  --c: #0000 64%,#000 66% 98%,#0000 101%;
  background:
    radial-gradient(35% 146% at 50% 159%,var(--c)) 0 0,
    radial-gradient(35% 126% at 50% -59%,var(--c)) 100% 100%;
  background-size: calc(200%/3) 50%;
  background-repeat: repeat-x;
  clip-path: inset(0 100% 0 0);
  animation: l16 20s infinite ease-in-out;
}
.loader_inff:after {
  scale: -1 1;
  
  animation-delay: -.75s;
}
@keyframes l16{
  50% {clip-path: inset(0)}
  to {clip-path: inset(0 0 0 100%)}
}




/* HTML: <div class="loader"></div> */
.loader_cblob {
  mix-blend-mode: exclusion;
  width: 280px;
  aspect-ratio: 1.1;
  border: 40px solid #000;
  box-sizing: border-box;
  background: 
    linear-gradient(#fff 0 0) 5px 50% /25px 50px,
    linear-gradient(#fff 0 0) 120px 50%/25px 50px,
    linear-gradient(#000 0 0) center/100% 25px,
    radial-gradient(farthest-side,#0000 calc(98% - 25px),#fff calc(100% - 25px) 98%,#0000),
    #000;
  background-repeat: no-repeat;
  filter: blur(16px) contrast(8); 
  animation: l15 6s infinite alternate;
}
@keyframes l15 {
  0%,30% {background-position:8px  50%,170px 50%,center,0 0}
  100%   {background-position:32px 50%,150px 50%,center,0 0}
}

.textStroke {
  text-shadow: -2px -2px 0 white, 0 -2px 0 white, 2px -2px 0 white, 2px 0 0 white,
    2px 2px 0 white, 0 2px 0 white, -2px 2px 0 white, -2px 0 0 white;
}

.textStroke_blk {
  text-shadow: -2px -2px 0 #000000, 0 -2px 0 #000000, 2px -2px 0 #000000, 2px 0 0 #000000,
    2px 2px 0 #000000, 0 2px 0 #000000, -2px 2px 0 #000000, -2px 0 0 #000000;
}


.widget-glass {
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.54);
  padding: 4px;
}

.text-label {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 12px;
  color: black;

}

.portal {
  cursor: pointer;
}

  .__react_arrowmaster {
    
  svg { 
    transition: .3s all;
  }
  svg > .path {
    stroke: blue;
  }
  .portal {
    .mirror {
      svg > path {
        stroke: transparent;
      }
    }
  }
}
.portalHolder.showNet .__react_arrowmaster svg {
    opacity: 1;
}
.portalHolder.showNet .net_circ {
    opacity: 1;
}
.portalHolder.showNet .net_rect {
    opacity: 1;
}
.portalHolder.hideNet .__react_arrowmaster svg {
    opacity: 0;
}
.portalHolder.hideNet .net_circ {
    opacity: 0;
}
.portalHolder.hideNet .net_rect {
    opacity: 0;
}

.portalHolder.hideNet .__react_arrowmaster .portal svg {
    opacity: 1;
}

.portal .mirror svg path:nth-of-type(1) {
    animation: none;
}

.portalHolder svg path:nth-of-type(4) {
    mix-blend-mode:hard-light; 
    -webkit-mix-blend-mode: hard-light;
    animation: net_fadecolor_3 6s infinite ease-in-out alternate;
    -webkit-animation: net_fadecolor_3 6s infinite ease-in-out alternate;
}
.portalHolder svg path:nth-of-type(5) {
    -webkit-animation: net_fadecolor_3 6s infinite ease-in-out alternate;
    animation: net_fadecolor_3 6s infinite ease-in-out alternate;
    mix-blend-mode:hard-light;
    -webkit-mix-blend-mode:hard-light;
}
.portalHolder svg path:nth-of-type(20) {
    -webkit-animation: net_fadecolor_3 6s infinite ease-in-out alternate;
    animation: net_fadecolor_3 6s infinite ease-in-out alternate;
    mix-blend-mode:hard-light;
    -webkit-mix-blend-mode:hard-light;
}
.portalHolder svg path:nth-of-type(21) {
    -webkit-animation: net_fadecolor_3 6s infinite ease-in-out alternate;
    animation: net_fadecolor_3 6s infinite ease-in-out alternate;
    mix-blend-mode:hard-light;
    -webkit-mix-blend-mode:hard-light;
}
.portalHolder svg path:nth-of-type(7) {
    stroke: rgb(11, 194, 167);
    stroke: #e827a5;
    mix-blend-mode:hard-light;
    -webkit-mix-blend-mode:hard-light;
    filter: blur(0px);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    animation: none;
}
.portalHolder svg path:nth-of-type(8) {
    stroke: rgb(11, 194, 167);
    stroke: #e827a5;
    mix-blend-mode:hard-light;
    -webkit-mix-blend-mode:hard-light;
    -webkit-filter: blur(0px);
    filter: blur(0px);
    animation: none;
}
.portalHolder svg path:nth-of-type(0) {
    -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation-delay: calc(0 * 0.3s);
    -webkit-mix-blend-mode: normal;
    mix-blend-mode: normal;
}
.portalHolder svg path:nth-of-type(1) {
    -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation-delay: calc(1* 0.3s);
    -webkit-mix-blend-mode: normal;
    mix-blend-mode: normal;
}
.portalHolder svg path:nth-of-type(2) {
    -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation-delay: calc(2* 0.3s);
    -webkit-mix-blend-mode: normal;
    mix-blend-mode: normal;
}
.portalHolder svg path:nth-of-type(3) {
    -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation-delay: calc(3* 0.3s);
    -webkit-mix-blend-mode: normal;
    mix-blend-mode: normal;
}
.portalHolder svg path:nth-of-type(6) {
    -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation-delay: calc(6* 0.3s);
    -webkit-mix-blend-mode: normal;
    mix-blend-mode: normal;
}
.portalHolder svg path:nth-of-type(9) {
    -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation-delay: calc(9* 0.3s);
    -webkit-mix-blend-mode: normal;
    mix-blend-mode: normal;
}
.portalHolder svg path:nth-of-type(10) {
    -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation-delay: calc(10 * 0.3s);
    -webkit-mix-blend-mode: normal;
    mix-blend-mode: normal;
}
.portalHolder svg path:nth-of-type(11) {
    -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation-delay: calc(11 * 0.3s);
    -webkit-mix-blend-mode: normal;
    mix-blend-mode: normal;
}
.portalHolder svg path:nth-of-type(12) {
    -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation-delay: calc(12 * 0.3s);
    -webkit-mix-blend-mode: normal;
    mix-blend-mode: normal;
}
.portalHolder svg path:nth-of-type(13) {
    -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation-delay: calc(13 * 0.3s);
    -webkit-mix-blend-mode: normal;
    mix-blend-mode: normal;
}
.portalHolder svg path:nth-of-type(14) {
    -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation-delay: calc(14 * 0.3s);
    -webkit-mix-blend-mode: normal;
    mix-blend-mode: normal;
}
.portalHolder svg path:nth-of-type(15) {
    -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation-delay: calc(15 * 0.3s);
    -webkit-mix-blend-mode: normal;
    mix-blend-mode: normal;
}
.portalHolder svg path:nth-of-type(16) {
    -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation: net_fadecolor 9s infinite ease-in-out alternate;
    animation-delay: calc(16 * 0.3s);
    -webkit-mix-blend-mode: normal;
    mix-blend-mode: normal;
}

.portalHolder svg path:nth-of-type(17) { 
    -webkit-animation: net_fadecolor_2 5s infinite ease-in-out alternate;
    animation: net_fadecolor_2 5s infinite ease-in-out alternate;
}
.portalHolder svg path:nth-of-type(18) { 
    -webkit-animation: net_fadecolor_2 5s infinite ease-in-out alternate;
    animation: net_fadecolor_2 5s infinite ease-in-out alternate;
}
.portalHolder svg path:nth-of-type(19) { 
    -webkit-animation: net_fadecolor_2 5s infinite ease-in-out alternate;
    animation: net_fadecolor_2 5s infinite ease-in-out alternate;
}
.portalHolder svg path:nth-of-type(20) { 
    -webkit-animation: net_fadecolor_2 5s infinite ease-in-out alternate;
    animation: net_fadecolor_2 5s infinite ease-in-out alternate;
}
.portalHolder svg path:nth-of-type(21) { 
    -webkit-animation: net_fadecolor_2 5s infinite ease-in-out alternate;
    animation: net_fadecolor_2 5s infinite ease-in-out alternate;
}
.portalHolder svg path:nth-of-type(22) { 
    -webkit-animation: net_fadecolor_2 5s infinite ease-in-out alternate;
    animation: net_fadecolor_2 5s infinite ease-in-out alternate;
}
.portalHolder svg path:nth-of-type(23) { 
    -webkit-animation: net_fadecolor_2 5s infinite ease-in-out alternate;
    animation: net_fadecolor_2 5s infinite ease-in-out alternate;
}
.portalHolder svg path:nth-of-type(24) { 
    -webkit-animation: net_fadecolor_2 5s infinite ease-in-out alternate;
    animation: net_fadecolor_2 5s infinite ease-in-out alternate;
}




.portalHolder {
  .net_circ, .net_rect {
    opacity: 0;
    transition: .3s all;
  }
  .__react_arrowmaster > svg {
    opacity: 0;
    transition: .5s all;
    
    :nth-child(2) { --nth-child: 2 }
    :nth-child(1) { --nth-child: 1 }
    :nth-child(0) { --nth-child: 0 }
    :nth-child(3) { --nth-child: 3 }
    :nth-child(4) { --nth-child: 4 }
    :nth-child(5) { --nth-child: 5 }
    :nth-child(6) { --nth-child: 6 }
    :nth-child(7) { --nth-child: 7 }
    :nth-child(8) { --nth-child: 8 }
    :nth-child(9) { --nth-child: 9 }
    :nth-child(10) { --nth-child: 10 }
    :nth-child(11) { --nth-child: 11 }
    :nth-child(12) { --nth-child: 12 }
    :nth-child(13) { --nth-child: 13 }
    :nth-child(14) { --nth-child: 14 }
    :nth-child(15) { --nth-child: 15 }
    :nth-child(16) { --nth-child: 16 }

    path:nth-child(-n+16) { 
        -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
        animation: net_fadecolor 9s infinite ease-in-out alternate;
        animation-delay: calc(var(--nth-child) * 0.3s);
        -webkit-mix-blend-mode: normal;
        mix-blend-mode: normal;
    }

    /* path:nth-child(0), path:nth-child(1), path:nth-child(2), path:nth-child(3), path:nth-child(6), path:nth-child(9), path:nth-child(10), path:nth-child(11), path:nth-child(12), path:nth-child(13), path:nth-child(14), path:nth-child(15), path:nth-child(16) {
        -webkit-animation: net_fadecolor 9s infinite ease-in-out alternate;
        animation: net_fadecolor 9s infinite ease-in-out alternate;
        animation-delay: calc(var(--nth-child) * 0.3s);
        -webkit-mix-blend-mode: normal;
        mix-blend-mode: normal;
    } */

    path:nth-child(n+17) {
        -webkit-animation: net_fadecolor_2 5s infinite ease-in-out alternate;
        animation: net_fadecolor_2 5s infinite ease-in-out alternate;
    }

    
    
    path:nth-child(4) {
      mix-blend-mode:hard-light; 
      -webkit-mix-blend-mode: hard-light;
      animation: net_fadecolor_3 6s infinite ease-in-out alternate;
      -webkit-animation: net_fadecolor_3 6s infinite ease-in-out alternate;
    }
    path:nth-child(5) {
      animation: net_fadecolor_3 6s infinite ease-in-out alternate;;
      mix-blend-mode:hard-light;
    }
    path:nth-child(20) {
      animation: net_fadecolor_3 6s infinite ease-in-out alternate;;
      mix-blend-mode:hard-light;
    }
    path:nth-child(21) {
    -webkit-animation: net_fadecolor_3 6s infinite ease-in-out alternate;
      animation: net_fadecolor_3 6s infinite ease-in-out alternate;
      -webkit-mix-blend-mode:hard-light;
      mix-blend-mode:hard-light;
    }
    path:nth-child(7) {
      stroke: rgb(11, 194, 167);
      stroke: #e827a5;
      mix-blend-mode:hard-light;
      -webkit-filter: blur(0px);
      filter: blur(0px);
      animation: none;
    }
    path:nth-child(8) {
      stroke: rgb(11, 194, 167);
      stroke: #e827a5;
      mix-blend-mode:hard-light;
      -webkit-filter: blur(0px);
      filter: blur(0px);
      animation: none;
    }

    
    /* path:nth-child(14) {
      stroke: blue;
      animation: none;
    } */
  }
  
}


@keyframes net_fadecolor{
  0% {
    stroke: #e827a5;
    stroke-width: 4px;
    opacity: 1;
  }
  60% {
    stroke: #e827a5;
    
    stroke-width: 4px;
    opacity: 1;
  }
  85% {
    stroke: #fd7e14;
    stroke: #e827a5;
    stroke-width: 8px;
    filter: blur(2px);
    -webkit-filter: blur(2px);
    opacity: .33;
  }
  100% {
    stroke: #e827a5;
    stroke-width: 4px;
    opacity: 1;
  }
}

@keyframes net_fadecolor_2{
  0% {
    stroke: #fdae14;
    stroke: #e827a5;
    stroke-width: 8px;
    opacity: .0;
  }
  50% {
    stroke: #fdae14;
    stroke: #e827a5;
    stroke-width: 8px;
    -webkit-filter: blur(2px);
    filter: blur(2px);
    opacity: .0;
  }
  70% {
    stroke: #fdae14;
    stroke: #e827a5;
    stroke-width: 8px;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: .5;
  }
  100% {
    stroke: #fdae14;
    stroke: #e827a5;
    stroke-width: 8px;
    -webkit-mix-blend-mode: hard-light;
    mix-blend-mode: hard-light;
    -webkit-filter: blur(2px);
    filter: blur(2px);
    opacity: .8;
  }
}
@-webkit-keyframes net_fadecolor_3 {
    0% {
      stroke: rgb(11, 194, 167);
      stroke: #e827a5;
      -webkit-filter: blur(2px);
      filter: blur(2px);
      stroke-width: 4px;
      opacity: .5;
    }
    50% {
      stroke: rgb(11, 194, 167);
      stroke: #e827a5;
      -webkit-filter: blur(4px);
      filter: blur(4px);
      stroke-width: 8px;
      opacity: .3;
    }
    85% {
      stroke: rgb(11, 194, 167);
      stroke: #e827a5;
      -webkit-filter: blur(2px);
      filter: blur(2px);
      stroke-width: 8px;
      opacity: .5;
    }
    100% {
      stroke: rgb(11, 194, 167);
      stroke: #e827a5;
      -webkit-filter: blur(0px);
      filter: blur(0px);
      stroke-width: 4px;
      opacity: .5;
    }
  }
@keyframes net_fadecolor_3 {
  0% {
    stroke: rgb(11, 194, 167);
    stroke: #e827a5;
    -webkit-filter: blur(2px);
    filter: blur(2px);
    stroke-width: 4px;
    opacity: .5;
  }
  50% {
    stroke: rgb(11, 194, 167);
    stroke: #e827a5;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    stroke-width: 8px;
    opacity: .3;
  }
  85% {
    stroke: rgb(11, 194, 167);
    stroke: #e827a5;
    -webkit-filter: blur(2px);
    filter: blur(2px);
    stroke-width: 8px;
    opacity: .5;
  }
  100% {
    stroke: rgb(11, 194, 167);
    stroke: #e827a5;
    -webkit-filter: blur(0px);
    filter: blur(0px);
    stroke-width: 4px;
    opacity: .5;
  }
}


.portalHolder.pinkArrows {
  .__react_arrowmaster > svg { 
    path {
      stroke: #e82755;
    }
  }
}
.portalHolder.whiteArrows {
  .__react_arrowmaster > svg > path {
    stroke: #ffffff;
  }
}
.portalHolder.blackArrows {
  .__react_arrowmaster > svg > path {
    stroke: #000000;
  }
}

.transition_03 {
  transition: .3s all;
}

.net_rect {
  width: 48px; 
  height: 32px;
  border: 1px white solid;
  background-color: 'rgba(255,255,255,.2)';
  font-size: 10px;
  font-weight: 800;
}

.net_circ {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  border: 1px solid white;
  font-size: 10px;
  font-weight: 800;
}
