@import url('https://fonts.googleapis.com/css2?family=Overpass+Mono:wght@300..700&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Onest:wght@900&display=swap');

.App {
  text-align: center;
}

.vh100 {
  height: calc(var(--vh, 1vh) * 100);
  height: 100dvh;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.txt {
  font-size: 28px;
  line-height: 32px;
  
}

.App-header {
  background-color: #282c34;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.button {
  opacity: .54;
  transition: .3s all;
  width: 240px;
  font-weight: 600;
  margin-right: 8px;
  margin-bottom: 12px;

}
.widget-glass {
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.54);
  padding: 4px;
}
.button:hover {
  opacity: 1;
}
.button+.button {
  
}
.lonkk {
color: #e82755;
cursor: pointer;
}
.lonkk:hover {
  text-decoration: underline ;
}

.rotateee {
  animation: rrrr 10s linear infinite;
  
  
}
@keyframes rrrr {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
@keyframes fadeIn {
  0% {
      opacity: 1;
      
  }
  40% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeInI {
  0% {
    opacity: 0;
    
}
40% {
  opacity: 0;
}
60% {
  opacity: 1;
}
100% {
  opacity: 1;
}
}

@keyframes fadeIn2H {
  from {
      opacity: 0.1;
      filter: blur(3px);
  }
}

@keyframes fadeIn2 {
  from {
      opacity: 0;
      filter: blur(3px);
  }
  
}
@keyframes fadeIn3 {
  0% {
      opacity: 0;
      filter: blur(3px);
  }
  30% {
    opacity: 0;
    filter: blur(3px);
}
  100% {
    opacity: 1;
    filter: blur(0px);
}

}

.translatez0 {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  backface-visibility: hidden;
  transition: .5s all;
  display: none;
  mix-blend-mode:color-dodge;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {


  .button {
   opacity: 1;
   
   width: 70%;
  padding: 8px;
  margin: 4px;
  margin-left: -8px;
  
  }
.txt {
  width: calc(100vw - 48px);
  margin: -24px;
  padding: 24px;
  padding-top: 200px;
  font-size: 20px;
  line-height: 24px;
  
  background: linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
}
}


.arrow {
  pointer-events: none;
}
 
.arrow__path {
  stroke: #fff;
  fill: transparent;
  stroke-dasharray: 4 2;
}
 
.arrow__head line {
  stroke: #fff;
  stroke-width: 1px;
  fill: white;
}
.arrow__head {
  fill: white;
}


